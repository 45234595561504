import Vue from 'vue'
import { IAppModule, ILink, IView } from '@/common/types'
import { Access } from '@/common/enums'

export default class ClinicModule implements IAppModule {
  async init(): Promise<any> {
    Vue.component('dplus-clinic-place', (await import('./components/dplus-clinic-place.vue')).default);
    Vue.component('dplus-clinic-fees', (await import('./components/dplus-clinic-fees.vue')).default);
    Vue.component('dplus-modal-list', (await import('./components/dplus-modal-list.vue')).default);
    Vue.component('dplus-modal-list-edit', (await import('./components/dplus-modal-list-edit.vue')).default);
    Vue.component('dplus-working-year', (await import('./components/dplus-working-year.vue')).default);

    Vue.component('ManipulationDialog', (await import('./views/ManipulationDialog.vue')).default);
    Vue.component('DamageDialog', (await import('./views/DamageDialog.vue')).default);
    Vue.component('LaboratoryDialog', (await import('./views/LaboratoryDialog.vue')).default);
    Vue.component('ResearchDialog', (await import('./views/ResearchDialog.vue')).default);
    Vue.component('SalaryDialog', (await import('./views/SalaryDialog.vue')).default);
    Vue.component('FeeHistoryDialog', (await import('./views/FeeHistoryDialog.vue')).default);
    Vue.component('MessageTemplatesList', (await import('./views/MessageTemplatesList.vue')).default);
    Vue.component('MessageTemplateDialog', (await import('./views/MessageTemplateDialog.vue')).default);
    Vue.component('MessageDialog', (await import('./views/MessageDialog.vue')).default);
  }

  async routes(): Promise<Array<ILink>> {
    return [

      { name: 'ClinicView', path: '/clinic', component: (await import('./views/ClinicView.vue')).default, access: Access.CompanyManager },
      { name: 'SalaryView', path: '/salary', component: (await import('./views/SalaryView.vue')).default, access: Access.CompanyManager },
      { name: 'FeesView', path: '/fees', component: (await import('./views/FeesView.vue')).default, access: Access.CompanyManager },
      { name: 'FeeHistoryView', path: '/fees-history', component: (await import('./views/FeeHistoryView.vue')).default, access: Access.CompanyManager },
      { name: 'FeeHistoryViewOwn', path: '/fees-history-own', component: (await import('./views/FeeHistoryView.vue')).default, access: Access.Any },
      { name: 'ManipulationsView', path: '/manipulations', component: (await import('./views/ManipulationsView.vue')).default, access: Access.CompanyManager },
      { name: 'DamagesView', path: '/damages', component: (await import('./views/DamagesView.vue')).default, access: Access.CompanyManager },
      { name: 'ResearchesView', path: '/researches', component: (await import('./views/ResearchesView.vue')).default, access: [Access.CompanyManager, Access.Doctor] },
      { name: 'LaboratoriesView', path: '/labs', component: (await import('./views/LaboratoriesView.vue')).default, access: Access.CompanyManager },
      { name: 'WorkingDaysView', path: '/work', component: (await import('./views/WorkingDaysView.vue')).default, access: Access.CompanyManager },
      { name: 'MessagesView', path: '/system/messages', component: (await import('./views/MessagesView.vue')).default, access: Access.CompanyManager }
    ]
  }

  async userMenu(): Promise<Array<IView>> {
    return [
      { name: 'SalaryMenu', component: (await import('./components/SalaryMenu.vue')).default }
    ]
  }

  async reports(): Promise<Array<IView>> {
    return [
      { name: 'Salary', component: (await import('./reports/SalaryReport.vue')).default },
      { name: 'Research', component: (await import('./reports/ResearchReport.vue')).default }
    ]
  }
}
