
import $app from '@/plugins/modules'
import { System } from '@/modules/system'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { IPatient } from '../types';
import { IResearch } from '@/modules/clinic/types';
import { ITableHeader } from '@/modules/system/types';
import { Clinic } from '@/modules/clinic';

@Component
export default class CustomerLabTab extends Vue {
  @Prop({ default: () => { } })
  readonly patient: IPatient | undefined;

  system = $app.module(System);
  clinic = $app.module(Clinic);

  ipp = $app.module(System).$store.getCurrentUser.rowsPerPage;
  researchVisible = false;
  researchSelected: IResearch | null = null;
  researchProcess = false;
  showFilter = false;

  filterFrom = new Date().addMonth(-1).native(false);
  filterTo = new Date().addMonth(1).native(false);

  get headers(): Array<ITableHeader> {
    return [
      {
        text: $app.i18n('system.Name'),
        value: 'LabName',
        align: 'left',
        width: '20%'
      },
      {
        text: $app.i18n('dplus.labs.ResearchId'),
        value: 'ResearchId',
        align: 'center',
        width: '10%'
      },
      {
        text: $app.i18n('dplus.labs.ResearchSended'),
        value: 'ResearchSended',
        align: 'left',
        width: '10%'
      },
      {
        text: $app.i18n('dplus.labs.ResearchDueDate'),
        value: 'ResearchDueDate',
        align: 'left',
        width: '10%'
      },
      {
        text: $app.i18n('dplus.labs.ResearchPrice'),
        value: 'ResearchPrice',
        align: 'right',
        width: '10%'
      },
      {
        text: '',
        value: 'ResearchState',
        align: 'center',
        width: '5%'
      }
    ];
  }

  get researches(): Array<IResearch> {
    const from = new Date(this.filterFrom);
    const to = new Date(this.filterTo);

    return !this.patient || !this.patient.researches
      ? []
      : this.patient.researches.filter(r => {
        if (this.showFilter) {
          return new Date(r.sended || new Date()).getTime() >= from.getTime() && new Date(r.sended || new Date()).getTime() < to.getTime();
        } else {
          return r.state === 'W' || r.state === 'N'
        }
      });
  }

  async open(research: IResearch) {
    if (this.patient) {
      this.researchSelected = $app.clone(research);
      this.researchVisible = true;
    }
  }

  append() {
    if (this.patient) {
      this.researchSelected = {
        id: null,
        laboratory: null,
        patient: this.patient.patientId,
        sended: null,
        dueDate: null,
        received: null,
        details: '',
        price: 0,
        state: 'N',
        color: '',
        doctor: null,
        patientName: this.patient.patientLastName + '' + this.patient.patientFirstName,
        patientPhone: this.patient.patientPhone,
        patientPhoto: this.patient.photo,
        labName: ''
      };
      this.researchVisible = true;
    }
  }

  async changeState(state: string) {
    this.researchProcess = true;
    try {
      const item: IResearch = $app.clone(this.researchSelected);
      item.state = state;
      await this.clinic.$store.setResearchState(item)

      if (this.patient && this.patient.researches) {
        const index = this.patient.researches.findIndex(r => r.id === item.id);
        if (index >= 0) {
          this.patient.researches.splice(index, 1, item);
        }
      }
      this.researchSelected = item;
    } catch (err) {
      $app.pushError(err);
    }
    this.researchProcess = false;
  }

  async store(item: IResearch) {
    this.researchProcess = true;
    try {
      item = await this.clinic.$store.storeResearch(item);
      if (this.patient && this.patient.researches) {
        const index = this.patient.researches.findIndex(r => r.id === item.id);
        if (index >= 0) {
          this.patient.researches.splice(index, 1, item);
        } else {
          this.patient.researches.push(item);
        }
      }
      this.researchSelected = item;
    } catch (err) {
      $app.pushError(err);
    }
    this.researchProcess = false;
  }

  async remove(item: IResearch) {
    this.researchProcess = true;
    try {
      await this.clinic.$store.removeResearch(item);
      if (this.patient && this.patient.researches) {
        const index = this.patient.researches.findIndex(r => r.id === item.id);
        if (index >= 0) {
          this.patient.researches.splice(index, 1);
        }
      }
      this.researchVisible = false;
    } catch (err) {
      $app.pushError(err);
    }
    this.researchProcess = false;
  }
}
